import BaseBean from "@/utils/BaseBean";
import PiCard from "@/views/project/order/order/pi/PiCard.vue";
import XyzCard from "@/views/project/order/finance/xyz/xyz/XyzCard.vue";
import OaApplyCard from "@/views/project/order/finance/oa/oaApply/OaApplyCard.vue";
import TtPayCard from "@/views/project/order/finance/tt/ttPay/TtPayCard.vue";
export interface IXbhkListDataObj {
    utilInst:XbhkListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class XbhkListUtil extends BaseBean{
    public dataObj:IXbhkListDataObj

    constructor(proxy:any,dataObj:IXbhkListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        this.dataObj.otherParams.customerData=await this.utils.OrderProviderApi.getAllCustomer({});//查询所有客户
    }
    //查看pi弹出框
    public async piDetail(piId:string):Promise<any>{
        await this.utils.UtilPub.openDialog({proxy:this.proxy,addOrEdit:'edit',id:piId,modelComp:PiCard,ownerComp:this.proxy,cardFrom:'SkqrList',
            title:'pi详情',contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true})
    }
    //查看支付详情弹出框
    public async payDetail(row:any,type:number):Promise<any>{
        let id=row.F_YFK_PAY_ID;
        let payType=row._F_YFK_PAY_TYPE;//支付方式
        let modelComp;//打开的弹出框是TT、信用证、OA
        if(1==type) {//尾款
            id=row.F_WK_PAY_ID;
            payType=row._F_WK_PAY_TYPE;
        }

        switch (payType) {
            case 0://TT
                let res1=await this.utils.OrderProviderApi.getTtPayIdByDetailId({params:{id:id}});
                id=res1.id;
                modelComp=TtPayCard; break;
            case 1://信用证
                let res2=await this.utils.OrderProviderApi.getXyzIdByDetailId({params:{id:id}});
                id=res2.id;
                modelComp=XyzCard; break;
            case 2://OA
                let res3=await this.utils.OrderProviderApi.getOaApplyIdByDetailId({params:{id:id}});
                id=res3.id;
                modelComp=OaApplyCard;break;
                break;
        }
        await this.utils.UtilPub.openDialog({proxy:this.proxy,addOrEdit:'edit',id:id,modelComp:modelComp,ownerComp:this.proxy,cardFrom:'SkqrList',
            title:'支付详情',contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true})
    }
    //回款确认
    public async hkqrHandler():Promise<any>{
        let selections:Array<any>=this.dataObj.pageListRef.getTbInst().getSelection();
        if(selections.length==0){
            this.proxy.$message('请选择要操作的行');
            return ;
        }
        if(selections[0].F_YFK_XBHK>0 && selections[0].F_WK_XBHK>0){
            //只要预付款和尾款可以确认了，哪怕它们不是全款支付，那么这里也一次性的确认它们，F_XBHK_AMOUNT是本次需要确认的总金额
            this.dataObj.otherParams.type=1;
            this.dataObj.otherParams.dialogTitle='【预付款、尾款】信保回款确认'
            this.dataObj.otherParams.selInfo.F_AMOUNT=this.utils.UtilPub.toAmountFormat(selections[0].F_XBHK_AMOUNT,false,2);
        }else if(selections[0].F_YFK_XBHK>0){
            this.dataObj.otherParams.dialogTitle='【预付款】信保回款确认'
            this.dataObj.otherParams.type=2;
            this.dataObj.otherParams.selInfo.F_AMOUNT=this.utils.UtilPub.toAmountFormat(selections[0].F_YFK_XBHK,false,2);
        }else if(selections[0].F_WK_XBHK>0){
            this.dataObj.otherParams.dialogTitle='【尾款】信保回款确认'
            this.dataObj.otherParams.type=3;
            this.dataObj.otherParams.selInfo.F_AMOUNT=this.utils.UtilPub.toAmountFormat(selections[0].F_WK_XBHK,false,2);
        }
        this.dataObj.otherParams.selInfo.F_PI_ID=selections[0].F_PI_ID;
        this.dataObj.otherParams.selInfo.F_ORDER_CODE=selections[0].F_ORDER_CODE;
        this.dataObj.otherParams.dialogVisible=true;
    }
    //收款确认发起请求处理
    public async doXbhk():Promise<any>{
        let selections:Array<any>=this.dataObj.pageListRef.getTbInst().getSelection();
        let params={id:selections[0].F_ID,type:this.dataObj.otherParams.type};
        let res=await this.utils.OrderProviderApi.xbhkAction({params:params});
        if(res.result){
            this.dataObj.pageListRef.queryHandler(true);
            this.utils.Tools.success();
            this.dataObj.otherParams.dialogVisible=false;
        }
    }
}